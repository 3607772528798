<template>
    <div>
        <div v-if="preloading">
            <div class="d-flex justify-content-center align-items-center preloading">
                <div class="loader-xl" />
            </div>
        </div>
        <div v-else>
            <div class="mb-3">
                <router-link to="/projects" class="text-decoration-none">
                    <b-button type="is-light" size="is-small" icon-left="backward">
                        {{ $t('projets.backToProjects') }}
                    </b-button>
                </router-link>
            </div>
            <div class="box">
                <ProjectForm v-model="project" class="mt-2" @onSave="doSave" @blacklistAdded="blacklistAdded"/>
            </div>
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import Toaster from '@/components/UI/Toaster'
    import { mapActions } from 'vuex'

    export default {
        name: 'ProjetEdit',
        components: {
            Toaster
        },
        data() {
            return {
                preloading: true,
                project: {},
                isCreation: true
            }
        },
        created() {
            if (this.$route.query.id && this.$route.query.id > 0) {
                let project = this.$store.state.projectEdit

                let idProject = this.$route.query.id
                this.isCreation = false

                if (project) {
                    this.project = project
                    this.preloading = false
                } else {
                    if (idProject && idProject > 0) {
                        this.axios
                            .get('/crud/projects/' + idProject, {
                                params: {
                                    include: 'blacklist,user',
                                    sort: 'name'
                                }
                            })
                            .then(response => {
                                this.project = response.data.project
                                this.preloading = false
                            })
                    }
                }
            } else {
                this.preloading = false
            }

            let title = this.isCreation ? 'projets.create' : 'projets.modify'

            this.setTitle(title)
        },
        methods: {
            ...mapActions('projects', ['addBlacklist']),
            doSave() {
                if (this.isCreation) {
                    this.axios.post('/crud/projects', this.project).then(
                        ({
                            data: {
                                project: { id }
                            }
                        }) => {
                            this.addBlacklist({
                                id: id,
                                params: {
                                    urls: this.project.blacklist
                                }
                            })
                                .then(blacklist => {
                                    this.value.blacklist = blacklist
                                })
                                .catch(e => {
                                    console.error(e)
                                })
                                .finally(() => {
                                    this.setSaved()
                                })
                        }
                    )
                } else {
                    this.axios.put('/crud/projects/' + this.project.id, this.project).then(() => {
                        this.setSaved()
                    })
                }
            },
            setSaved() {
                this.$refs.toaster.fire(
                    'info',
                    this.$t('commande.validation.front.saveddetails'),
                    this.$t('commande.validation.front.saved')
                )

                this.$router.push('/projects')
            },
            blacklistAdded() {
                this.axios
                    .get('/crud/projects/' + this.$route.query.id, {
                        params: {
                            include: 'blacklist,user',
                            sort: 'name'
                        }
                    })
                    .then(response => {
                        this.project = response.data.project
                        this.preloading = false
                    })
            }
        }
    }
</script>
